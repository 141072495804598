import { getConnectedContents } from "@/services/api";
import { useState, useEffect } from "react";
import useGetContentTypeUuid from "./useGetContentUuid";

function useGetConnectedContents(content_type, content_uuid) {
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const contentType = useGetContentTypeUuid(content_type);

  const getContents = async function () {
    try {
      setLoading(true);
      const response = await getConnectedContents(
        contentType.uuid,
        content_uuid
      );
      setItems(response.data.data || []);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContents();
  }, [content_type, content_uuid]);

  return [items, isLoading];
}

export default useGetConnectedContents;
