import { env } from "@/next.config";
import axios from "axios";
import httpUtils from "@/utils/httpUtils";

export async function getIndustryList() {
  return httpUtils.get(`${env.API_VERSION}/industry`);
}

export async function getProfileList() {
  return httpUtils.get(`${env.API_VERSION}/profile-role`);
}

export async function countryList() {
  const countries = (await import("@/assets/json/countries.json")).default;
  return countries;
}

export async function submitContactUs(payload) {
  return httpUtils.post(`${env.API_VERSION}/contact`, payload);
}

export async function registrationGitex(payload) {
  return httpUtils.post(`${env.API_VERSION}/register-event`, payload);
}

export async function getEventUser(payload) {
  return httpUtils.get(`${env.API_VERSION}/registered-user-event`, payload);
}

export async function verifyCaptcha(token) {
  return httpUtils.post(`${env.API_VERSION}/verify-captcha`, {
    token,
  });
}

export async function submitGetInTouch(payload) {
  return httpUtils.post(`${env.API_VERSION}/get-in-touch`, payload);
}

export async function submitRequestPitchDeck(payload) {
  return httpUtils.post(`${env.API_VERSION}/request-pitch-deck`, payload);
}

export async function sendToken(payload) {
  return httpUtils.post(`${env.API_VERSION}/send-token`, payload);
}

export async function verifyToken(payload) {
  return httpUtils.post(`${env.API_VERSION}/verify-token`, payload);
}

export async function sendTokenNotUser(payload) {
  return httpUtils.post(`${env.API_VERSION}/send-token-not-user`, payload);
}

export async function verifyTokenNotUser(payload) {
  return httpUtils.post(`${env.API_VERSION}/verify-token-not-user`, payload);
}

export async function verifyTokenForUserRegister(payload) {
  return httpUtils.post(`${env.API_VERSION}/verify-token-for-user-register`, payload);
}

export async function getCityByCountry(countryId) {
  return httpUtils.get(`${env.API_VERSION}/city/by-country/${countryId}`);
}
