import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useGetCurrentUrl } from "@/hooks";
import { HandGrabbing, X } from "@phosphor-icons/react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import ClaimSuccess from "./ClaimSuccess";
import { useEffect, useState } from "react";
import { userDetail } from "@/services/api/User";
import toast from "react-hot-toast";

const StartupOwner = ({ startup, isOpen, setIsOpen }) => {
  const fullUrl = useGetCurrentUrl();
  const [startupOwner, setStartupOwner] = useState(null)

  const getUserDetail = async (id) => {
    try {
      const response = await userDetail(id)
      setStartupOwner(response.data)
    } catch (error) {
      setIsOpen(false)
      toast.error('Can not get user information!')
    }
  }

  useEffect(() => {
    getUserDetail(startup.owner_id)
  }, [startup])

  return (
    <AlertDialog open={isOpen} onClose={() => setIsOpen(false)}>
      {/* <AlertDialogTrigger asChild>
        <Button
          className='px-3 py-2 mt-1 text-xs bg-white text-background hover:bg-white/80'
          size='xs'
        >
          {`Who Claimed This?`}
        </Button>
      </AlertDialogTrigger> */}
      <AlertDialogContent className='bg-white text-background'>
        <div className='pb-3'>
          <p className='text-xl font-semibold tracking-tight'>
            {`${startup.name} Owner's Information`}
          </p>
          <div className='pt-6 -my-2.5 divide-y divide-background/10'>
            <div className='flex items-center justify-between py-2.5'>
              <p className='w-1/3 text-sm opacity-70'>Name</p>
              <p className='flex-1 text-sm font-medium'>{startupOwner?.name}</p>
            </div>
            <div className='flex items-center justify-between py-2.5'>
              <p className='w-1/3 text-sm opacity-70'>Email</p>
              <p className='flex-1 text-sm font-medium'>
                {startupOwner?.email}
              </p>
            </div>
            <div className='flex items-center justify-between py-2'>
              <p className='w-1/3 text-sm opacity-70'>Role</p>
              <p className='flex-1 text-sm font-medium'>{startupOwner?.role || '-'}</p>
            </div>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button onClick={() => setIsOpen(false)} className='w-full border-0 bg-background/5 hover:bg-background/10'>
              <X weight='bold' className='mr-1.5' />
              Close
            </Button>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default StartupOwner;
