import { resetPassword } from '@/services/api/User';
import { useRouter } from 'next/router';
import { useState } from 'react';
import toast from 'react-hot-toast';

const useResetPassword = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({});

  const clearErrors = () => {
    setErrors({});
  }

  const submit = async () => {
    const { token, email } = router.query;

    const formattedEmail = email.replace(/ /g, "+");

    if (!token) {
      router.replace('/auth/forgot-password');
      return;
    }

    clearErrors();
    const errors = {};

    if (!email) {
      router.replace('/auth/forgot-password');
      return;
    }

    if (!newPassword) {
      errors['newPassword'] = 'New password is required.';
    }

    if (newPassword && newPassword.length < 8) {
      errors['newPassword'] = 'Minimum length 8 characters.';
    }

    if (newPassword && newPassword !== confirmNewPassword) {
      errors['confirmNewPassword'] = 'New password confirmation does not match.';
    }

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await resetPassword(
        token,
        {
          password: newPassword,
          password_confirmation: confirmNewPassword,
          email: formattedEmail,
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message, {
          duration: 6000
        });
        setTimeout(() => {
          router.push('/auth/login');
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred while resetting your password.');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    newPassword, setNewPassword,
    confirmNewPassword, setConfirmNewPassword,
    submit,
    isLoading,
    errors,
  };
};

export default useResetPassword;
