import AppContext from "@/AppContext";
import { getContentTypeUuid } from "@/utils/content";
import { useContext } from "react";

function useGetContentTypeUuid(content_type) {
  if (!content_type) return null;
  const appContext = useContext(AppContext);
  const type = getContentTypeUuid(content_type, appContext.state.contentTypes);

  return type;
}

export default useGetContentTypeUuid;
