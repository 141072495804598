import LucidMascot from "@/assets/images/lucid-mascot.png";
import { DotLottiePlayer } from "@dotlottie/react-player";
import "@dotlottie/react-player/dist/index.css";
import * as Dialog from "@radix-ui/react-dialog";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import React from "react";
import { toast } from "react-hot-toast";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { X } from "@phosphor-icons/react";

const ClaimSuccess = ({
  url,
  title,
  description,
  startup,
  isOpen,
  setOpen,
  disabled,
}) => {
  const router = useRouter();

  const copyUrl = async function () {
    try {
      await navigator.clipboard.writeText(url);
      toast.success("URL copied!");
    } catch (error) {
      console.log("Error", error);
      toast.error("Cannot copy the URL!");
    }
  };
  return (
    <Dialog.Root open={isOpen} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <>
            <div className='fixed inset-0 z-[89] w-screen h-screen bg-background/80 backdrop-blur-sm'></div>
            {/* <div className='fixed top-0 z-[89] left-0 w-full h-full lg:flex'>
              <div className='hidden w-full lg:inline'>
                <DotLottiePlayer
                  autoplay
                  loop
                  mode='normal'
                  src='https://lottie.host/d32eae98-9229-4dd2-8832-55c0eb02a2ea/30DNE5oGCm.lottie'
                />
              </div>
              <div className='w-full'>
                <DotLottiePlayer
                  autoplay
                  loop
                  mode='normal'
                  src='https://lottie.host/d32eae98-9229-4dd2-8832-55c0eb02a2ea/30DNE5oGCm.lottie'
                />
              </div>
            </div> */}
          </>
        </Dialog.Overlay>
        <Dialog.Content className='fixed bg-white text-background left-[50%] top-[50%] z-[99] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-4 lg:p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full'>
          <button
            onClick={() => setOpen(false)}
            type='button'
            className='absolute z-10 top-4 right-4'
          >
            <X weight='bold' className='text-2xl text-background' />
          </button>
          <div className='text-center'>
            <div className='relative mx-auto h-24 aspect-[199/320] mb-3 -mt-16'>
              <Image
                src={LucidMascot.src}
                alt=''
                layout={"fill"}
                objectFit={"cover"}
              />
            </div>
            <p className='text-xl font-semibold tracking-tight lg:leading-tight lg:text-2xl'>
              Congratulations, <br /> You have{" "}
              {startup && startup.is_published ? "claimed" : "registered"}{" "}
              {startup.name}!
            </p>
            <p className='mt-4 text-sm leading-snug'>
              Let everyone know about your startup at Lucidity Insights and
              share it to the world!
            </p>
          </div>
          <div className='flex flex-1 gap-3'>
            <Input
              value={url}
              readOnly
              className='text-xs bg-white shadow-sm border-background/20 ring-offset-white'
            />
            <Button
              variant='secondary'
              onClick={copyUrl}
              className='flex-shrink-0 text-xs'
            >
              Copy URL
            </Button>
          </div>
          <div className='flex items-center w-full space-x-3'>
            <span className='flex-1 h-px bg-background/10'></span>
            <span className='text-[10px] uppercase font-medium tracking-wider'>
              or share to
            </span>
            <span className='flex-1 h-px bg-background/10'></span>
          </div>
          <div className='flex items-center justify-center gap-1.5'>
            <FacebookShareButton
              url={url + "?utm_source=facebook&utm_medium=social"}
              quote={title + " - " + description}
              className='disabled:cursor-not-allowed'
            >
              <FacebookIcon
                size={32}
                round
                iconFillColor={"#fff"}
                bgStyle={{ fill: "#000" }}
                className='duration-300 ease-out hover:opacity-100 disabled:cursor-not-allowed'
              />
            </FacebookShareButton>
            <TwitterShareButton
              url={url + "?utm_source=twitter&utm_medium=social"}
              title={`Hi i've published ${title} startup at Lucidity Insights!`}
              className='inline-flex items-center disabled:cursor-not-allowed'
            >
              <span className='inline-flex items-center justify-center w-8 h-8 text-white duration-300 ease-out bg-black rounded-full'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-4 duration-300 ease-out disabled:cursor-not-allowed'
                  viewBox='0 0 24 24'
                >
                  <path
                    fill='currentColor'
                    d='M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231l5.45-6.231Zm-1.161 17.52h1.833L7.045 4.126H5.078L17.044 19.77Z'
                  />
                </svg>
              </span>
            </TwitterShareButton>
            <LinkedinShareButton
              url={url + "?utm_source=linkedin&utm_medium=social"}
              title={title}
              summary={description}
              className='disabled:cursor-not-allowed'
            >
              <LinkedinIcon
                size={32}
                round
                iconFillColor={"#fff"}
                bgStyle={{ fill: "#000" }}
                className='duration-300 ease-out hover:opacity-100 disabled:cursor-not-allowed'
              />
            </LinkedinShareButton>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ClaimSuccess;
