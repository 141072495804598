import { useRouter } from "next/router";

function useGetCurrentUrl() {
  const { asPath } = useRouter();
  const baseUrl = process.env.BASE_URL;

  if (asPath.charAt(0) === '/') {
    return `${baseUrl}${asPath.substring(1)}`;
  }

  return `${baseUrl}${asPath}`;
}

export default useGetCurrentUrl;
