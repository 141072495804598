import { getRelatedContent, getRelatedContentName, getRelatedContentNameByEntity } from "@/services/api";
import { useState, useEffect } from "react";
import useGetContentTypeUuid from "./useGetContentUuid";

function useGetRelatedContents(contentType, contentUuid, count = null, is_random = false, entity = null) {
  const [related_contents, setRelatedContents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const type = useGetContentTypeUuid(contentType);

  const getRelatedItems = async function () {
    setLoading(true);
    try {
      const response = await getRelatedContentName(contentType, contentUuid, count, is_random);
      setRelatedContents(response.data ? response.data.data : []);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const getRelatedItemsByEntity = async function () {
    setLoading(true);
    try {
      const response = await getRelatedContentNameByEntity(contentType, count, is_random, entity);
      setRelatedContents(response.data ? response.data.data : []);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entity) {
      getRelatedItemsByEntity()
    } else {
      getRelatedItems();
    }
  }, [contentType, contentUuid]);

  return [related_contents, isLoading];
}

export default useGetRelatedContents;
