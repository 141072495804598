import { getContentsByContentType } from "@/services/api";
import { useState, useEffect } from "react";
import useGetContentTypeUuid from "./useGetContentUuid";

function useGetContentsByContentType(content_type, params = {}) {
  const contentTypeUuid = useGetContentTypeUuid(content_type).uuid;
  const [contents, setContents] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getContents = async function () {
    setLoading(true);
    try {
      const response = await getContentsByContentType(contentTypeUuid, params);
      setContents(response.data.data || []);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContents();
  }, [content_type]);

  return [contents, isLoading];
}

export default useGetContentsByContentType;
